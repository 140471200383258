<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <div>
            <div class="row">
              <div class="col-12 col-xl-9 col-lg-8 col-md-7">
                <div class="card" v-if="user">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 col-xl-6 d-flex justify-content-between flex-column">
                        <div class="d-flex justify-content-start">
                          <b-avatar
                              :src="user.avatar"
                              :text="`${user.name.charAt(0).toUpperCase()} ${user.surname ? user.surname.charAt(0).toUpperCase() : ''}`"
                              variant="light-primary"
                              size="104px"
                              rounded
                          />
                          <div class="d-flex flex-column ml-1">
                            <div class="mb-1">
                              <h4 class="mb-0">{{ user.name }} {{ user.surname }}</h4>
                              <span class="card-text">{{ user.email }}</span>
                            </div>
                            <div class="d-flex flex-wrap">
                              <router-link
                                  :to="{ name: 'user-update', params: { id: user.id } }"
                                  class="btn btn-primary"
                              >
                                Редактировать
                              </router-link>
                              <button class="btn btn-outline-danger ml-1">Удалить</button>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex align-items-center mt-2">
                          <div class="d-flex align-items-center mr-2">
                            <b-avatar variant="light-primary" rounded>
                              <feather-icon icon="DollarSignIcon" size="18"/>
                            </b-avatar>
                            <div class="ml-1">
                              <h5 class="mb-0">
                                <span class="amount">{{ turnover.monthly ? turnover.monthly.amount : 0 }} </span>
                                <span class="currency">{{
                                    turnover.monthly ? turnover.monthly.currency.name : 'UAH'
                                  }}</span>
                              </h5>
                              <small>Месячный оборот</small>
                            </div>
                          </div>
                          <div class="d-flex align-items-center">
                            <b-avatar variant="light-success" rounded>
                              <feather-icon icon="TrendingUpIcon" size="18"/>
                            </b-avatar>
                            <div class="ml-1">
                              <h5 class="mb-0">
                                <span class="amount">{{ turnover.yearly ? turnover.yearly.amount : 0 }} </span>
                                <span class="currency">{{
                                    turnover.yearly ? turnover.yearly.currency.name : 'UAH'
                                  }}</span>
                              </h5>
                              <small>Годовой оборот</small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-xl-6">
                        <table class="mt-2 mt-xl-0 w-100">
                          <tr>
                            <th class="pb-50">
                              <feather-icon icon="UserIcon" class="mr-75"/>
                              <span class="font-weight-bold">Роли</span>
                            </th>
                            <td>{{ user.roles }}</td>
                          </tr>
                          <tr>
                            <th class="pb-50">
                              <feather-icon icon="BriefcaseIcon" class="mr-75"/>
                              <span class="font-weight-bold">Название компании</span>
                            </th>
                            <td class="pb-50">{{ user.company && user.company.name }}</td>
                          </tr>
                          <tr>
                            <th class="pb-50">
                              <feather-icon icon="CheckIcon" class="mr-75"/>
                              <span class="font-weight-bold">Статус</span>
                            </th>
                            <td class="pb-50 text-capitalize">{{ user.is_active ? 'Активный' : 'Неактивный' }}</td>
                          </tr>
                          <tr>
                            <th class="pb-50">
                              <feather-icon icon="StarIcon" class="mr-75"/>
                              <span class="font-weight-bold">Последний вход</span>
                            </th>
                            <td class="pb-50 text-capitalize">{{ user.last_visit | formattedDate }}</td>
                          </tr>
                          <tr>
                            <th class="pb-50">
                              <feather-icon
                                  icon="FlagIcon"
                                  class="mr-75"
                              />
                              <span class="font-weight-bold">Город</span>
                            </th>
                            <td class="pb-50">
                              {{ user.company && user.company.city }}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <feather-icon icon="PhoneIcon" class="mr-75"/>
                              <span class="font-weight-bold">Контактный телефон</span>
                            </th>
                            <td>{{ user.phone }}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-5 col-xl-3 col-lg-4" v-if="user">
                <div class="card border-primary">
                  <div class="card-header d-flex justify-content-between align-items-center pt-75 pb-4">
                    <h5 class="mb-0">Текущий тариф:</h5>
                    <b-badge variant="light-primary">{{ user.group.name }}</b-badge>
                  </div>
                  <div class="card-body">
                    <ul class="list-unstyled my-1">
                      <li>
                        <span class="align-middle">Комиссия: {{ Math.round((user.group.profit - 1) * 100) }}%</span>
                      </li>
                    </ul>
                    <button class="btn btn-primary btn-block">Изменить</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6">
                <div class="card">
                  <div class="card-body">
                    <div class="mx-2 mb-2">
                      <div class="row">
                        <div
                            class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                          <span class="text-muted">
                            Показано с {{ orders.from }} по {{ orders.to }} из {{ orders.total }} записей
                          </span>
                        </div>
                        <div
                            class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                          <b-pagination
                              v-model="currentOrderPage"
                              :total-rows="orders.total"
                              :per-page="orders.per_page"
                              first-number
                              last-number
                              class="mb-0 mt-1 mt-sm-0"
                              prev-class="prev-item"
                              next-class="next-item"
                          >
                            <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                          </b-pagination>
                        </div>
                      </div>
                    </div>
                    <div class="position-relative table-responsive">
                      <table class="table b-table">
                        <thead>
                        <!---->
                        <tr>
                          <th>#</th>
                          <th>Дата</th>
                          <th>Статус</th>
                          <th>К-во</th>
                          <th v-if="$can('read', 'payments')">Сумма</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="order in orders.data" :key="order.id">
                          <td>
                            <router-link
                                :to="{ name: 'order-details', params: { id: order.id }}"
                                class="font-weight-bold"
                            >
                              #{{ order.id }}
                            </router-link>
                          </td>
                          <td>{{ $formatters.formattedDate(order.created_at) }}</td>
                          <td>{{ order.status.name }}</td>
                          <td>{{ order.qty }}</td>
                          <td v-if="$can('read', 'payments')">
                            <span class="amount" v-for="(item, index) in order.sum" :key="index">
                              <span class="amount">{{ item.amount }}</span>
                              <span class="currency">{{ item.currency }}</span>
                              <br/>
                            </span>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="mx-2 mb-2">
                      <div class="row">
                        <div
                            class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                            <span class="text-muted">
                              Показано с {{ orders.from }} по {{ orders.to }} из {{ orders.total }} записей
                            </span>
                        </div>
                        <div
                            class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                          <b-pagination
                              v-model="currentOrderPage"
                              :total-rows="orders.total"
                              :per-page="orders.per_page"
                              first-number
                              last-number
                              class="mb-0 mt-1 mt-sm-0"
                              prev-class="prev-item"
                              next-class="next-item"
                          >
                            <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                          </b-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="card">
                  <div class="card-body">
                    <div class="mx-2 mb-2">
                      <div class="row">
                        <div
                            class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                          <span class="text-muted">
                            Показано с {{ payments.from }} по {{ payments.to }} из {{ payments.total }} записей
                          </span>
                        </div>
                        <div
                            class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                          <b-pagination
                              v-model="currentPaymentPage"
                              :total-rows="payments.total"
                              :per-page="payments.per_page"
                              first-number
                              last-number
                              class="mb-0 mt-1 mt-sm-0"
                              prev-class="prev-item"
                              next-class="next-item"
                          >
                            <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                          </b-pagination>
                        </div>
                      </div>
                    </div>
                    <div class="position-relative table-responsive">
                      <table class="table b-table">
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>Дата</th>
                          <th>Счет</th>
                          <th>Сумма</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="payment in payments.data" :key="payment.id">
                          <td class="font-weight-bold">#{{ payment.id }}</td>
                          <td>{{ $formatters.formattedDate(payment.created_at) }}</td>
                          <td>{{ payment.payment_account.name }}</td>
                          <td>
                            <span
                                :class="['amount', ['in', 'insupplier'].includes(payment.direction) ? 'positive' : 'negative']">
                              {{ payment.sum }}
                            </span>
                            <span class="currency">{{ payment.currency.name }}</span>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="mx-2 mb-2">
                      <div class="row">
                        <div
                            class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ payments.from }} по {{ payments.to }} из {{ payments.total }} записей
                  </span>
                        </div>
                        <div
                            class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                          <b-pagination
                              v-model="currentPaymentPage"
                              :total-rows="payments.total"
                              :per-page="payments.per_page"
                              first-number
                              last-number
                              class="mb-0 mt-1 mt-sm-0"
                              prev-class="prev-item"
                              next-class="next-item"
                          >
                            <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                          </b-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar, BPagination, BBadge } from 'bootstrap-vue';

export default {
  components: {
    BAvatar,
    BPagination,
    BBadge,
  },
  data() {
    return {
      user: null,
      currentOrderPage: 1,
      currentPaymentPage: 1,
      orders: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
        total: null,
        per_page: 10,
      },
      turnover: {
        monthly: 0,
        yearly: 0,
      },
      payments: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
      },
    };
  },
  watch: {
    async currentOrderPage(newValue) {
      this.orders.current_page = newValue;
      await this.getOrders();
    },
    async currentPaymentPage(newValue) {
      this.payments.current_page = newValue;
      await this.getPayments();
    },
  },
  async mounted() {
    await this.getUser();
    await this.getOrders();
    await this.getPayments();
    await this.getTurnover();
  },
  methods: {
    async getUser() {
      this.user = (await this.$api.users.get(this.$route.params.id)).data;
    },
    async getTurnover() {
      this.turnover = (await this.$api.users.turnover(this.$route.params.id)).data;
    },
    async getOrders() {
      this.orders = (await this.$api.orders.list({
        user_id: this.$route.params.id,
        ...(this.orders.current_page > 1 && { page: this.orders.current_page }),
        limit: 25,
      })).data;
    },
    async getPayments() {
      this.payments = (await this.$api.payments.list({
        user_id: this.$route.params.id,
        ...(this.payments.current_page > 1 && { page: this.payments.current_page }),
        limit: 25,
      })).data;
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';

.amount {
  white-space: nowrap;

  & span.amount {
    font-size: 18px;

  }

  & span.currency {
    font-size: 10px;
  }
}

span.amount {
  font-size: 18px;
}

.positive {
  color: $success;
}

.negative {
  color: $danger;
}

span.currency {
  font-size: 10px;
}
</style>
